import React from "react"
import PropTypes from "prop-types"

import Stack from "./stack"
import Heading from "./heading"
import Box from "./box"
import Text from "./text"
import Inline from "./inline"
import Divider from "./divider"

const Indicator = ({ bg }) => {
  return (
    <Box
      bg={bg}
      sx={{
        width: 3,
        height: 3,
        borderRadius: "100%",
        display: "inline-block",
      }}
    />
  )
}

const Score = ({ statistics, showCorrect, showIncorrect, showMissed }) => {
  return (
    <Box
      p={6}
      sx={{
        borderWidth: 2,
        borderColor: "border",
      }}
    >
      <Stack space={6}>
        <Heading as="h4">Deine Score</Heading>
        <Stack space={3}>
          {showCorrect && (
            <Inline space={2} alignY="center">
              <Indicator bg="positive" />
              <Text sans>{statistics.correct} Richtig</Text>
            </Inline>
          )}
          {showMissed && (
            <Stack space={3}>
              <Divider />
              <Inline space={2} alignY="center">
                <Indicator bg="caution" />
                <Text sans>{statistics.missed} Vergessen</Text>
              </Inline>
            </Stack>
          )}
          {showIncorrect && (
            <Stack space={3}>
              <Divider />
              <Inline space={2} alignY="center">
                <Indicator bg="negative" />
                <Text sans>{statistics.incorrect} Falsch</Text>
              </Inline>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}

Score.propTypes = {
  showIncorrect: PropTypes.bool,
  showMissed: PropTypes.bool,
  showCorrect: PropTypes.bool,
}

Score.defaultProps = {
  showIncorrect: true,
  showMissed: true,
  showCorrect: true,
}

export default Score
